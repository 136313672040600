import React, { useState } from 'react';
import { GComputer2 } from 'src/assets';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { BtnLink, NextLink } from 'src/interface/basics/links';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText, SectionBtns } from 'src/interface/basics/sections';
import { LazyImg } from 'src/interface/vendors/lazyload-wrapper';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';

const NotFoundSection = ({ className = '' }) => {
  const [ animation, setAnimation ] = useState('');

  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-right')}>
      <section id='not_found' className={`py-8 animate ${animation} ${className}`}>
        <Container>
          <Row className='flex-center'>
            <Col sm={12} md={{span: 6, offset: 1, order: 2}}>
              <div className='mb-5'>
                <LazyImg src={GComputer2} alt='Computer photo'/>
              </div>
            </Col>
            <Col sm={12} md={{span: 5, order: 1}}>
              <SectionPretitle>404</SectionPretitle>
              <SectionTitle>Not Found</SectionTitle>
              <SectionText>
                We're sorry.
                The resource you were looking for has either moved or no longer exists.
                Please try again.
              </SectionText>
              <SectionBtns>
                <NextLink to='/' className='btn-text-primary'>View Homepage</NextLink>
              </SectionBtns>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default NotFoundSection;
